import {Injectable} from '@angular/core';
import {
    MediaSelectModalComponent,
    MediaSelectModalData
} from '@common/media/media-select-modal/media-select-modal.component';
import {FileEntry, FileEntryType} from '@common/uploads/types/file-entry';
import {concat, first, map, toArray} from 'rxjs/operators';
import {Modal} from '@common/core/ui/dialogs/modal.service';
import {Projects} from '../../shared/projects/projects.service';
import {flatMap} from 'rxjs/internal/operators';
import {EMPTY, Observable, of} from 'rxjs';
import {BuilderStateService} from '../builder-state.service';
import {getRelativeProjectUploadUrl} from '@common/uploads/utils/get-relative-project-upload-url';

@Injectable({
    providedIn: 'root'
})
export class ImageElementService {
    constructor(
        private modal: Modal,
        private state: BuilderStateService,
        private projectService: Projects,
    ) {
    }

    public openModal(): Observable<string | undefined> {
        const projectId = this.state.project$.value.model.id;
        return this.modal.show(MediaSelectModalComponent, {
            filterType: FileEntryType.image,
            multiselect: false,
            showProjects: true,
        } as MediaSelectModalData).afterClosed().pipe(
            map((data?: FileEntry[]) => {
                if (!data) return;

                return data[0];
            }),
            flatMap((media?: FileEntry) => {
                if (!media) return of(undefined);

                return this.projectService.addMedia(projectId, media.url).pipe(
                    first(),
                    map(response => {
                        const url = response.url;
                        return url.substring(url.indexOf('images/'));
                    })
                );
            }));
    }

    public openModalMultiSelect(): Observable<string[] | undefined> {
        const projectId = this.state.project$.value.model.id;
        return this.modal.show(MediaSelectModalComponent, {
            filterType: FileEntryType.image,
            multiselect: true,
            showProjects: true,
        } as MediaSelectModalData).afterClosed().pipe(
            flatMap((data?: FileEntry[]) => {
                if (!data) return of(undefined);

                return EMPTY.pipe(
                    concat(...data.map((media: FileEntry) => {
                        return this.projectService.addMedia(projectId, media.url).pipe(
                            first(),
                            map(response => {
                                return getRelativeProjectUploadUrl(response.url);
                            })
                        );
                    })),
                    toArray()
                );
            }));
    }
}
