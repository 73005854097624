import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MediaIndexComponent} from '@common/media/media-index/media-index.component';
import {defaultsDeep} from 'lodash';
import {FileEntryType} from '@common/uploads/types/file-entry';

export interface MediaSelectModalData {
    filterType?: FileEntryType;
    multiselect?: boolean;
    showProjects?: boolean;
    showAgencies?: boolean;
}

@Component({
    selector: 'media-select-modal',
    templateUrl: './media-select-modal.component.html',
    styleUrls: ['./media-select-modal.component.scss'],
})
export class MediaSelectModalComponent {

    @ViewChild(MediaIndexComponent, {static: true}) mediaIndex: MediaIndexComponent;

    constructor(
        private dialogRef: MatDialogRef<MediaSelectModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MediaSelectModalData,
    ) {
        defaultsDeep(data, {
            filterType: undefined,
            multiselect: false,
            showProjects: false,
            showAgencies: true,
        } as MediaSelectModalData);
    }

    /**
     * Close the modal.
     */
    public close(data?: any) {
        this.dialogRef.close(data);
    }

    confirm() {
        this.close(this.mediaIndex.selectedItems);
    }

    canConfirm(): boolean {
        if (this.data.multiselect) {
            return this.hasSelectedItems();
        } else {
            return this.getSelectedItemsCount() === 1;
        }
    }

    hasSelectedItems(): boolean {
        return this.getSelectedItemsCount() > 0;
    }

    getSelectedItemsCount(): number {
        return this.mediaIndex?.selectedItems?.length ?? 0;
    }
}
