<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title trans>Media select</h2>

    <button type="button" (click)="close()" class="close-button no-style icon-button">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <media-index
        [filterType]="data.filterType"
        [selectMode]="true"
        [showAgencies]="data.showAgencies"
        [showProjects]="data.showProjects"
    ></media-index>
</mat-dialog-content>

<div class="buttons right mat-dialog-actions" mat-dialog-actions>
    <button (click)="close()" type="button" mat-button class="button cancel" trans>
        Cancel
    </button>
    <button type="submit" class="button primary" mat-raised-button color="accent"
            (click)="confirm()"
            [disabled]="!canConfirm()">
        <span trans>Select</span><span *ngIf="hasSelectedItems()"> ({{getSelectedItemsCount()}})</span>
    </button>
</div>
